interface PaginationItem {
  label: string;
  value: number;
}

export function paginate(currentPage: number, totalPages: number, maxVisiblePages: number): PaginationItem[] {
  const pages: PaginationItem[] = []
  const ellipsis = '...'

  if (totalPages <= maxVisiblePages) {
    for (let page = 1; page <= totalPages; page++) {
      pages.push({ label: String(page), value: page })
    }
  } else {
    const halfVisiblePages = Math.floor(maxVisiblePages / 2)
    let startPage = currentPage - halfVisiblePages
    let endPage = currentPage + halfVisiblePages

    if (startPage <= 0) {
      endPage += Math.abs(startPage) + 1
      startPage = 1
    } else if (endPage > totalPages) {
      startPage -= endPage - totalPages
      endPage = totalPages
    }

    if (startPage > 1) {
      pages.push({ label: '1', value: 1 })
      if (startPage > 2) {
        pages.push({ label: ellipsis, value: startPage - 1 })
      }
    }

    for (let page = startPage; page <= endPage; page++) {
      pages.push({ label: String(page), value: page })
    }

    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        pages.push({ label: ellipsis, value: endPage + 1 })
      }
      pages.push({ label: String(totalPages), value: totalPages })
    }
  }

  return pages
}
