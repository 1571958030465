<template>
  <div v-if="hasPages" class="pagination">
    <div class="pagination__wrapper">
      <app-button
        v-if="showMore && !isLastPage"
        class="pagination__show-more"
        :label="showMoreLabel"
        :icon="arrowRotateRight"
        color="light"
        @click="emit('showMore')"
      />

      <div class="pagination__element">
        <app-button
          class="lt-md"
          :icon="iconCheveronsLeft"
          color="light"
          square
          :disabled="isFirstPage"
          :to="getTo(1, $route)"
        />

        <app-button
          :icon="iconCheveronLeft"
          color="light"
          square
          :disabled="isFirstPage"
          :to="getTo(page - 1, $route)"
        />

        <div class="pagination__pages gt-sm">
          <app-button
            v-for="item in elements"
            :key="item.value"
            :label="item.label"
            square
            :color="item.value === page ? 'primary' : 'light'"
            :to="getTo(item.value, $route)"
          />
        </div>

        <div class="pagination__page lt-md">
          {{ t('translate.pageOf', { n: page, total: totalPages }) }}
        </div>

        <app-button
          :icon="iconChevronRight"
          color="light"
          square
          :disabled="isLastPage"
          :to="getTo(page + 1, $route)"
        />

        <app-button
          class="lt-md"
          :icon="iconCheveronsRight"
          color="light"
          square
          :disabled="isLastPage"
          :to="getTo(totalPages, $route)"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import iconChevronRight from 'assets/icons/light/chevron-right.svg?raw'
import iconCheveronsRight from 'assets/icons/light/chevrons-right.svg?raw'
import iconCheveronLeft from 'assets/icons/light/chevron-left.svg?raw'
import iconCheveronsLeft from 'assets/icons/light/chevrons-left.svg?raw'
import arrowRotateRight from 'assets/icons/light/arrow-rotate-right.svg?raw'
import type { Pagination } from 'src/types'
import type { RouteLocationRaw, RouteLocationNormalizedLoaded } from 'vue-router'
import { paginate } from 'src/utils/pagination'
import { computed } from 'vue'
import { useI18n } from 'src/composables/useI18n'

const props = withDefaults(defineProps<{
  pagination: Pagination
  showMore?: boolean
}>(), {
  showMore: true
})

const emit = defineEmits<{
  (e: 'showMore'): void
}>()

const page = computed(() => props.pagination.page)

const totalPages = computed(() => props.pagination.numPages)

const totalResults = computed(() => props.pagination.total)

const perPage = computed(() => props.pagination.perPage)

const hasPages = computed(() => totalPages.value > 1)

const isLastPage = computed(() => page.value === totalPages.value)

const isFirstPage = computed(() => page.value === 1)

const nextPageResultsCount = computed(() => {
  if (isLastPage.value) return 0
  const count = totalResults.value - (page.value * perPage.value)
  return Math.min(count, perPage.value)
})

const getTo = (newPage: number, route: RouteLocationNormalizedLoaded): RouteLocationRaw | undefined => {
  if (newPage < 1 || newPage > totalPages.value) return undefined
  const page = newPage === 1 ? undefined : newPage
  return { query: { ...route.query, page } }
}

const { t } = useI18n()

const showMoreLabel = computed(() => {
  return t('actions.showMoreCount', { n: nextPageResultsCount.value })
})

const elements = computed(() => {
  return paginate(page.value, totalPages.value, 5)
})
</script>

<style lang="scss" scoped>
.pagination {
  display: flex;
  justify-content: center;
  &__show-more {
    margin-bottom: var(--md);
    min-width: 100%;
  }
  &__element {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: var(--md);
  }
  &__pages {
    display: flex;
    gap: var(--sm);
  }
  &__page {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  @media (max-width: $breakpoint-sm-max) {
    &__wrapper {
      width: 100%;
    }
  }

  @media (max-width: $breakpoint-xs-max) {
    &__element {
      width: 100%;
      display: flex;
      justify-content: space-between;
      gap: var(--container-px);
    }
  }
}
</style>
